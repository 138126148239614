(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.disableGA = disableGA;
exports.enableGA = enableGA;
exports.initGA = initGA;
exports.track = track;
var _localStorageService = require("./local-storage-service");
var ANALYTICS_CONENT_KEY = 'ANALYTICS_ACCEPTED';
function isGAEnabled() {
  return (0, _localStorageService.getItem)(ANALYTICS_CONENT_KEY) === 'true' ? true : false;
}
function disableGA() {
  (0, _localStorageService.removeItem)(ANALYTICS_CONENT_KEY);
  window.location.reload();
}
function enableGA() {
  (0, _localStorageService.setItem)(ANALYTICS_CONENT_KEY, 'true');
  window.location.reload();
}
function track(eventName, value) {
  var isEnabled = isGAEnabled();
  if (isEnabled === true) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      value: value
    });
    // return googleAnalyticsClient().track(eventName, payload, options, callback);
  }
}
function initGA() {
  var isEnabled = isGAEnabled();
  if (isEnabled === true) {
    var gtmKey = document.getElementById('gtmKey');
    if (gtmKey && gtmKey.value) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', gtmKey.value);
    }
  }
}

},{"./local-storage-service":2}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setItem = exports.removeItem = exports.getItem = exports.clear = void 0;
var clear = exports.clear = function clear() {
  if (typeof window !== 'undefined') {
    localStorage.clear();
  }
};
var getItem = exports.getItem = function getItem(key) {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key);
  }
};
var removeItem = exports.removeItem = function removeItem(key) {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key);
  }
};
var setItem = exports.setItem = function setItem(key, value) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, value);
  }
};

},{}],3:[function(require,module,exports){
"use strict";

var _analyticsHelpers = require("./analytics-helpers");
(function init_page_feedback() {
  var yesButton = document.getElementById('page-is-useful');
  var noButton = document.getElementById('page-is-not-useful');
  var successBanner = document.querySelector('.js-prompt-success');
  var promptQuestion = document.querySelector('.js-prompt-questions');
  var registerClick = function registerClick(event, value) {
    event.preventDefault();
    if (successBanner) {
      successBanner.removeAttribute('hidden');
    }
    if (promptQuestion) {
      promptQuestion.setAttribute('hidden', true);
    }
    (0, _analyticsHelpers.track)('page_is_useful', value);
  };
  if (yesButton) {
    yesButton.addEventListener('click', function (event) {
      return registerClick(event, 'YES');
    });
  }
  if (noButton) {
    noButton.addEventListener('click', function (event) {
      return registerClick(event, 'NO');
    });
  }
})();

},{"./analytics-helpers":1}]},{},[3]);
